<!--
 功能名称:千里眼平台(短信)-发送记录
 开发人:梁高权
 创建时间:2021/05/21
 最后修改时间:2021/05/21
-->
<template>
  <div class="sendRecord-wrap">
    <NavBar/>
    <van-pull-refresh v-model="refreshing" @refresh="onRefresh">
      <van-list
        v-model="loading"
        :finished="finished"
        finished-text="没有更多了"
        @load="onLoad"
        offset="0"
      >
      <div class="content">
        <div class="line" v-for="(item,index) in list" :key="index">
          <div class="son">
            <div class="Sun">
              <h5>发送时间：</h5>
              <span>{{item.send_time}}</span>
            </div>
            <i :style="{color:item.send_sms_num>0?'#00D175':'#F3355B'}">{{item.send_sms_num>0?'发送成功':'发送失败'}}</i>
          </div>
          <div class="son">
            <div class="Sun">
              <h5>发送短信数</h5>
              <span>{{item.send_sms_num}}条</span>
            </div>
            <div class="Sun">
              <h5>发送状态</h5>
              <span>成功{{item.send_succ_num}}人，失败{{item.send_fail_num}}人</span>
            </div>
          </div>
          <div class="template">{{item.send_content}}</div>
          <div class="son lineHeight">
            <div class="Sun" style="display: flex;align-items: center;">
              <h5 style="margin: 0;">短信操作人：</h5>
              <span>{{item.phone}}</span>
            </div>
            <div class="copy" v-clipboard:copy="item.send_content" v-clipboard:success="onCopy" v-clipboard:error="onError">
              <img src="@/assets/images/copy.png">
              <span>复制内容</span>
            </div>
          </div>
        </div>
      </div>
      </van-list>
    </van-pull-refresh>
  </div>
</template>

<script>
import { Toast } from 'vant'
import table from '@/api/table'
import NavBar from '@/components/NavBar'
export default{
  name:"sendRecord",
  components:{NavBar},
  data(){
    return{
      list: [],
      loading: false,
      finished: false,
      refreshing: false,
      param:{
        page:1,
        limit:5
      }
    }
  },
  mounted() {
  },
  methods:{
    onLoad() {
      if (this.refreshing) {
        this.list = [];
        this.refreshing = false;
      }
      table.recordList(this.param).then(response=>{
        this.list=this.list.concat(response.data.list)
        this.loading = false;
        if (this.list.length >= response.data.total_rows) {
          this.finished = true;
        }
      })
    },
    onRefresh() {
      // 清空列表数据
      this.finished = false;

      // 重新加载数据
      // 将 loading 设置为 true，表示处于加载状态
      this.loading = true;
      this.onLoad();
    },
    // 复制成功时的回调函数
    onCopy(e) {
      Toast.success('复制成功');
    },
    // 复制失败时的回调函数
    onError(e) {
      Toast.fail('复制失败')
    }
  }
}
</script>

<style lang="scss" scoped>
.sendRecord-wrap{
  .content{
    padding: 28px 30px;
    .line{
      background: #fff;
      margin-bottom: 19px;
      padding: 0 25px;
      border-radius: 16px;
      .son{
        display: flex;
        justify-content: space-between;
        padding-top:36px;
        i{
          font-size: 30px;
          font-weight: bold;
          color: #00D175;
        }
      }
      .template{
        margin-top: 35px;
        background: #F3F3F3;
        border-radius: 16px;
        padding: 30px 25px;
      }
      .lineHeight{
        padding-bottom:36px;
      }
      .Sun{
        font-size: 26px;
        font-weight: 500;
        h5{color: #999;margin-bottom: 15px}
        span{color:#333}
      }
      .copy{
        display: flex;
        align-items: center;
        img{width: 21px;height: 24px;margin-right: 10px}
        span{font-size: 24px;font-weight: bold;color: #2A2A3B}
      }
    }
  }
}
</style>
